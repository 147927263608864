.adm-rate {
  --star-size: 24px;
  --active-color: #ffd21e;
  --inactive-color: var(--adm-color-light);
  display: inline-flex;
}
.adm-rate-box {
  position: relative;
}
.adm-rate-star {
  padding: calc(var(--star-size) / 8);
  line-height: var(--star-size);
  font-size: var(--star-size);
  color: var(--inactive-color);
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
}
.adm-rate-star-half {
  padding-right: 0;
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
}
.adm-rate-star-active {
  color: var(--active-color);
}
.adm-rate-star-readonly {
  cursor: unset;
}
