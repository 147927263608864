.adm-tabs {
  --title-font-size: 17px;
  --content-padding: 12px;
  --active-line-height: 2px;
  --active-line-border-radius: var(--active-line-height);
  position: relative;
}
.adm-tabs-header {
  position: relative;
  border-bottom: solid 1px var(--adm-border-color);
}
.adm-tabs-tab-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
}
.adm-tabs-tab-list::-webkit-scrollbar {
  display: none;
}
.adm-tabs-tab-wrapper {
  padding: 0 12px;
}
.adm-tabs-tab-wrapper-stretch {
  flex: auto;
}
.adm-tabs-tab {
  white-space: nowrap;
  padding: 8px 0 10px;
  width: min-content;
  margin: 0 auto;
  font-size: var(--title-font-size);
  position: relative;
  cursor: pointer;
}
.adm-tabs-tab-active {
  color: var(--adm-color-primary);
}
.adm-tabs-tab-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.adm-tabs-tab-line {
  position: absolute;
  bottom: 0;
  height: var(--active-line-height);
  color: var(--adm-color-primary);
  background: var(--adm-color-primary);
  border-radius: var(--active-line-border-radius);
}
.adm-tabs-content {
  padding: var(--content-padding);
}
.adm-tabs-header-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  height: 100%;
  pointer-events: none;
}
.adm-tabs-header-mask-left {
  left: 0;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}
.adm-tabs-header-mask-right {
  right: 0;
  background: linear-gradient(to left, #fff, rgba(255, 255, 255, 0));
}
